<template>
    <div class="admin-container" >
        <h1 v-if="info">Questions/Réponses</h1>
        <h2> {{ ` ${info.name} - ${info.speaker_title} ${info.firstname} ${info.lastname}`}}</h2>
        <template v-if="!webinar || webinar.length === 0">
            <p class="no-question">Aucune question pour le moment.</p>
        </template>
        <template v-else>
        <div class="back-bar">
            <router-link to="/admin/webinar-list" class="back-btn"></router-link>
            <vue-excel-xlsx
                class="btn-form"
                :data="webinar"
                :columns="columns"
                :filename="`export_${info.number}_${info.name}`"
                :sheetname="`${info.number}_${info.name}`"
                >
                Export
            </vue-excel-xlsx>
        </div>
        <div class="table-container" v-if="webinar">
            <table>
                <thead>
                    <tr>
                        <th>Current Time</th>
                        <th>Question</th>
                        <th>Réponse</th>
                        <th>Email</th>                        
                    </tr>
                </thead>
                <tbody>   
                    <tr v-for="(item, idx) in webinar" :key="idx">
                        <td>{{ item.webinar_video_question_current_time }}</td>
                        <td>{{ item.webinar_video_question_question }}</td>
                        <td>
                            <span v-if="item.webinar_video_question_answer && item.webinar_video_question_answer.length > 0">{{ item.webinar_video_question_answer }}</span>
                            <span v-else>/</span>                            
                        </td>
                        <td>{{ item.email}}</td>                       
                    </tr>                                   
                </tbody>
            </table>
        </div>
        </template>
 
        <NotifError :errorTxt="errorTxt"  v-if="errorTxt" v-on:closeModal="errorTxt = null"></NotifError>
    </div>
</template>

<script>
import axios from 'axios'
import store from './../../store'
import NotifError from './../../components/NotifError'

export default {
    name : 'AdminWebinarVideo',
    components : {
        NotifError
    },
    data() {
        return {
            webinar : null,
            info : null,
            errorTxt : null,    
            columns : [
                {
                    label: "Current time",
                    field: "webinar_video_question_current_time",
                },
                {
                    label: "Question",
                    field: "webinar_video_question_question",
                },
                        {
                    label: "Réponse",
                    field: "webinar_video_question_answer",
                },
                        {
                    label: "Email",
                    field: "email",
                },
            ]       
        }
    },
    mounted() {
        let vm = this

        this.errorTxt = null

        axios
            .get(store.getters.getURL + "webinars/getVideoQuestions.php?id=" + this.$route.params.id + "&token=" + store.state.JWT_TOKEN )
            .then(function (response) { 
                if(response.data.state == 'success') {
                    vm.webinar= response.data.res
                    vm.info = response.data.info
                    console.log('webinar =>', vm.webinar)
                }else {
                    if(response.data.authError) {
                        store.commit('logout')
                        vm.$router.push('/login')
                    }else {
                        vm.errorTxt = 'An error has occured: ' + response.data.retour
                    }
                }
            })
            .catch((err) => {
                this.errorTxt = 'An error has occured: ' +  err
            });
    },
    methods : {
        displayDate(item) {
            const date = new Date(item);
            const formattedDate = date.toLocaleString("fr-FR", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
            });

            return formattedDate;
        },
    }, 
}
</script>

<style lang="scss" scoped>
    .back-bar {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }

    .no-question {
        margin-top:80px;
        font-size:18px;
    }
</style>